<template>
  <v-card
    color="transparent"
  >
    <v-card-title class="ml-2">
      Resumo da importação do lote: <span
        :style="{fontWeight: 'bold'}"
        class="pl-2"
      > {{ lote }} </span>
      <v-spacer />
      <v-btn
        color="#388e3c"
        dark
        depressed
        @click="confirmarImportacao();"
      >
        <v-icon class="pr-2">
          save
        </v-icon>
        confirmar importação
      </v-btn>
    </v-card-title>
    <v-expansion-panels
      v-model="panel"
      multiple
      flat
    >
      <v-expansion-panel :style="{background: ''}">
        <v-expansion-panel-header :style="{fontSize: '18px', fontWeight: 'bold'}">
          Identificação de lote 
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <IdentificaImportacao
            :key="attIdentificador"
            :confirmar="false"
            :dados="identificacao"
            @identificar="novaIdentificacao"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        class="mt-2"
      >
        <v-expansion-panel-header :style="{fontSize: '18px', fontWeight: 'bold'}">
          Produtos
        </v-expansion-panel-header>
        <v-expansion-panel-content :style="{height: '450px', overflowY: 'auto'}">
          <div>
            <v-data-table
              :headers="headers"
              :items="produtos"
              item-key="name"
              :search="pesquisar"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="pesquisar"
                  flat
                  solo
                  label="Pesquisar"
                  :background-color="$vuetify.theme.dark ? '#333' : '#eee'"
                  class="mx-4"
                />
              </template>
            </v-data-table>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>

import IdentificaImportacao from './IdentificaImportacao.vue'
export default {
  components: {IdentificaImportacao},
  props: {
    prods: {
      type: Object,
      required: true,
      default: () => {return {}},
    },
    identificacao: {
      type: Object,
      required: false,
      default: () => { return {} },
    },
  },

  data (){
    return {
      panel: ["",1],
      lote: this.identificacao.desc,
      attIdentificador: 0,
      identfica: {},
      produtos: this.prods.dados,
      pesquisar: '',
      headers: [
        {
          text: 'Cód. barras',
          align: 'start',
          sortable: true,
          value: 'barras',
        },
        // {
        //   text: 'Cód. barras aux.',
        //   align: 'start',
        //   sortable: true,
        //   value: 'baraux',
        // },
        {
          text: 'SKU',
          align: 'start',
          sortable: true,
          value: 'sku',
        },
        {
          text: 'Referência',
          align: 'start',
          sortable: true,
          value: 'ref',
        },
        {
          text: 'Produto',
          align: 'start',
          sortable: true,
          value: 'prod',
        },
        // {
        //   text: 'Situação',
        //   align: 'start',
        //   sortable: true,
        //   value: 'situac',
        // },
        {
          text: 'Grupo',
          align: 'start',
          sortable: true,
          value: 'ds_grp',
        },
        {
          text: 'Subgrupo',
          align: 'start',
          sortable: true,
          value: 'ds_subgrupo',
        },
        {
          text: 'Departamento',
          align: 'start',
          sortable: true,
          value: 'ds_depto',
        },
        {
          text: 'Seção',
          align: 'start',
          sortable: true,
          value: 'ds_secao',
        },
      ],
    }
  },

  watch: {
    identificacao:{
      immediate: true,
      handler(val){
        this.attIdentificador++;
      },
    },
  },

  methods: {
    novaIdentificacao(value){
      const novo = value
      this.lote = novo.desc;
      this.$emit('nova-identificacao', novo)
    },

    confirmarImportacao() {
      this.$emit("ok");
    },
  },
}
</script>

<style>

</style>