<template>
  <v-card
    v-if="options.image"
    ref="card"
    height="45vh"
    :disabled="options.dados.length ? false : true"
    class="importCard transitionCard ma-4"
    flat
    @mouseenter="toggleClass"
    @mouseleave="toggleClass"
    @click="options.dados.length ? (dialogCard = true) : null"
  >
    <v-card-title
      class="d-flex justify-center"
      :style="{ height: '10%' }"
    >
      {{ options.id }}
    </v-card-title>
    <v-card-text :style="{ height: '100%' }">
      <div
        class="d-flex flex-column justify-end align-center"
        :style="{ height: '90%', gap: '2rem' }"
      >
        <span :style="{ fontSize: '4rem' }">{{ options.dados.length }}</span>
        <img :src="options.image">
      </div>
    </v-card-text>

    <DialogProds
      v-model="dialogCard"
      :produtos="options"
      :chave="dialogCard"
      @produto="salvar"
    />
  </v-card>
</template>

<script>
import DialogProds from "./DialogProds.vue";
export default {
  components: { DialogProds },
  props: {
    img: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogCard: false,
    };
  },
  methods: {
    salvar(val) {
      const obj = val;
      this.options.dados[obj.id] = obj.prod;
      this.$emit("salvar", {
        id: obj.id,
        prod: obj.prod,
        array: this.options.id,
      });
    },
    toggleClass() {
      if (!this.$refs.card.$el.style.background.length) {
        this.$refs.card.$el.style.background = `${this.options.color}2a`;
        this.$refs.card.$el.style.borderColor = this.options.color;
        return;
      }
      this.$refs.card.$el.style.background = "";
      this.$refs.card.$el.style.borderColor = "";
    },
  },
};
</script>

<style>
.transitionCard {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
.importCard {
  user-select: none;
  background: #fafafa;
  border: 2px solid;
  border-radius: 0.5rem !important;
  padding: 2rem;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.importCard:hover {
  position: relative;
  scale: 1.05;
}
.importCard img {
  width: 100%;
  max-height: 180px;
}
</style>