<template>
  <div>
    <v-col class="d-flex justify-center">
      <v-card
        class="dropZone transition-all"
        :style="{ width: '50vw', height: '30vh' }"
        flat
        @dragenter="dragging = true"
        @dragleave="dragging = false"
      >
        <div
          class="dropZone-info"
          @drag="uploadArquivo"
        >
          <div class="dropZone-info-texts">
            <v-icon
              color="primary"
              size="56"
            >
              cloud_upload
            </v-icon>
            <span class="dropZone-title">
              Arraste o arquivo
              <br>
              ou <br>
              Clique para fazer upload
            </span>
            <v-progress-linear
              v-if="importing"
              :style="{ width: '30vw' }"
              color="primary"
              :value="loadingProgress"
              height="10"
            />
          </div>
        </div>
        <input
          accept=".xlsx"
          type="file"
          @change="uploadArquivo"
        >
      </v-card>
    </v-col>
  </div>
</template>

<script>
// import ProductBatchUpload from "@/components/ProductBatchUpload";

import XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
export default {
  // components: { ProductBatchUpload },
  data() {
    return {
      file: "",
      filelist: [],
      dragging: false,
      importing: false,
      loadingProgress: 0,
    };
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".xlsx").pop() : "";
    },
  },
  methods: {
    async uploadArquivo(file) {
      this.importing = true;
      // this.$refs.batch.openUploadDrawer();
      const arquivo = file.target.files[0];
      const arr = await readXlsxFile(arquivo);
      const produtos = [];

      arr.forEach((item, i) => {
        if (i == 0) {
          this.addProgress(700, 30);
        }
        if (i > 1) {
          produtos.push({
            itemKey: i,
            barras: item[0],
            baraux: item[1],
            sku: this.trimUpper(item[2]),
            ref: this.trimUpper(item[3]),
            prod: this.trimUpper(item[4]),
            situac: item[5] || true, // Se não vier preenchido, força como ativo
            fabric: item[6],
            cd_grp: item[7],
            ds_grp: this.trimUpper(item[8]),
            cd_subgrupo: item[9],
            ds_subgrupo: this.trimUpper(item[10]),
            cd_depto: item[11],
            ds_depto: this.trimUpper(item[12]),
            cd_secao: item[13],
            ds_secao: this.trimUpper(item[14]),
            cd_colec: item[15],
            ds_colec: this.trimUpper(item[16]),
            cd_categ: item[17],
            ds_categ: this.trimUpper(item[18]),
            cd_marca: item[19],
            ds_marca: this.trimUpper(item[20]),
            tp_potica: item[21],
            unidade: this.trimUpper(item[22]) || 'PC', // Se não vier preenchido, força como PC
            origem: item[23],
            ncm: item[24],
            exncm: item[25],
            custo: item[26],
            venda: item[27],
            venant: 0,
            status: 0, // 0 - novo / 1 - atualizar
          });
        }
      });
      this.addProgress(800, 70);
      this.$emit("importXLSX", produtos);
    },
    trimUpper(val) {
      const campo =
        val != null && val != undefined
          ? val.toString().trim().toUpperCase()
          : null;
      return campo;
    },
    addProgress(tempo, quantidade) {
      for (let i = 0; i < quantidade; i++) {
        setTimeout(() => {
          this.loadingProgress++;
          if (this.loadingProgress == 100) {
            setTimeout(() => {
              this.$emit("loaded");
            }, 500);
          }
        }, tempo);
      }
    },
  },
};
</script>

<style>
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
.dropZone {
  width: 80%;
  height: 200px;
  border: 2px dashed #ccc !important;
  border-radius: 2rem !important;
}

.dropZone:hover {
  border: 2px solid var(--v-primary-base) !important;
  background-color: #f162361c;
}

.dropZone:hover .dropZone-title {
  color: var(--v-primary-base);
}

.dropZone-info {
  color: #a8a8a8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.dropZone-info-texts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-uploaded {
  width: 80%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>