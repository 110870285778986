<template>
  <v-card
    flat
    color="transparent"
  >
    <v-btn
      text
      color="primary"
      @click="download"
    >
      {{ $t("TelaImportacao.download") }}
    </v-btn>
  </v-card>
</template>

<script>
import { tabelaProdutos } from "./index.js";
export default {
  data() {
    return {
      arquivo: tabelaProdutos,
      nomeArquivo: "SaaS - Template importação de produto.xlsx",
    };
  },
  methods: {
    download() {
      let element = document.createElement("a");
      element.setAttribute("href", this.arquivo);
      element.setAttribute("download", this.nomeArquivo);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<style>
</style>